.registration-container {
  background: linear-gradient(to right, #2c3e50, #4c669f);
  font-family: "Gilroy", sans-serif;
  color: white;
  text-align: center;
  padding: 50px;
}

.go-back-btn {
  display: inline-block;
  position: absolute;
  top: 40px;
  left: 50px;
  background-color: transparent;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out;
}

.registration-container h1 {
  font-size: 3rem;
  font-weight: bold;
}

.registration-container h2 {
  font-size: 1.5rem;
  margin-bottom: 50px;
}

.registration-form h3 {
  margin-bottom: 20px;
}

.input-group {
  position: relative;
}

.input-icon {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.7);
}

@media screen and (max-width: 767px) {
  .input-icon {
    left: -20px;
  }
  .go-back-btn {
    top: 30px;
    left: 15px;
  }
}

.registration-form input[type="text"] {
  display: block;
  font-family: "Gilroy", sans-serif;
  margin: 20px auto;
  padding: 15px;
  width: 80%;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  color: white;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s, background-color 0.3s;

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  outline: none;
  &:focus {
    background: rgba(255, 255, 255, 0.3);
    border-color: #8470ff;
    box-shadow: 0 0 0 3px rgba(132, 112, 255, 0.5);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
}

.registration-form button {
  background: #136fd6;
  border: none;
  font-family: "Gilroy", sans-serif;
  border-radius: 20px;
  padding: 15px 30px;
  color: white;
  margin-top: 20px;
  cursor: pointer;
}

.registration-form p {
  margin-top: 30px;
}

.success-message {
  color: green;
  margin-top: 20px;
}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0; /* Adjust as needed */
}

.carousel-container img {
  width: 50%; /* Adjust the size of the images */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Rounded corners for a softer look */
}

.carousel-button {
  background-color: transparent;
  border: none;
  color: white; /* Adjust color to match your theme */
  cursor: pointer;
  padding: 10px;
  font-size: 20px; /* Size of the arrow */
}

.carousel-button:hover {
  color: #136fd6; /* Change on hover, adjust color as needed */
}

@media screen and (max-width: 767px) {
  .carousel-container img {
    width: 80%; /* Larger images on smaller screens */
  }
}
