@tailwind base;
@tailwind components;
@tailwind utilities;

/* Reset default margins and paddings */
body,
h1,
h2,
h3,
ul,
li,
p {
  margin: 0;
  padding: 0;
}

.form-container {
  max-height: 80vh;
  overflow-y: auto;
}

/* NOT NEEDED ANYTHING BELOW THIS */
body {
  font-family: "Gilroy", sans-serif;
}

@keyframes PastelColorAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.link-form {
  text-decoration: underline;
  font-weight: bold;
  color: #000000;
  font-size: 1.1rem;
}

a.form-page {
  color: #333;
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
}

/* Login Container */
.auth-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.back-button {
  position: absolute;
  font-size: 20px;
  color: #000000;
  text-decoration: none;
}

.image-container {
  text-align: center;
  margin-bottom: 20px;
}

.image-container img {
  max-width: 120px;
  height: auto;
}

/* Form input styles */
.auth-form-group {
  margin-bottom: 15px;
}

.auth-form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: normal;
}

.form-terms {
  font-size: 13px;
  margin-left: 2px;
}

.form-terms-link {
  font-size: 13px;
  font-weight: bold;
  text-decoration: underline;
  color: blue;
}

.auth-form-group input:not([type="checkbox"]),
.auth-form-group select,
.auth-form-group textarea {
  font-family: "Gilroy", sans-serif;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Button styles */
.auth-btn {
  font-family: "Gilroy", sans-serif;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 15px;
  margin-bottom: 30px;
  margin-top: 10px;
  background: linear-gradient(90deg, #000000, #333333, #555555, #000000);
  background-size: 200% 100%;
  animation: DarkGradientAnimation 3s linear infinite;
  position: relative;
}

.loading-text {
  font-size: 16px;
  color: green;
  font-weight: bold;
  background-color: white;
}

.success-text {
  font-size: 15px;
  color: green;
  font-weight: bold;
  background-color: white;
  margin-bottom: 20px;
}

@keyframes DarkGradientAnimation {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

/* Error message styles */
.auth-error-message {
  color: red;
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: normal;
}

.required-star {
  color: red;
}
